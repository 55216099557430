<template>
  <div>
    <transition name="asuo" appear>
      <main id="content" role="main" region="main content">
        <!-- section-explore-atlas -->
        <section-explore-atlas
          v-if="$page && exploreItems"
          :title="$page.core404.title ? $page!.core404.title : ''"
          title-level="h1"
          title-size="small"
          eventSection="Explore information"
          @selectItem="handleSelectItemClick($event, `${$page!.core404.title}`)"
          @linkClick="handleLinkClick($event, `${$page!.core404.title}`)"
          @updateItem="handleUpdateItemClick($event, `${$page!.core404.title}`)"
          :items="exploreItems"
        >
          <template #body>
            <p class="fs-large fw-bold mb-space-md">
              We can’t find the requested URL “{{ currentRoute.fullPath }}”
            </p>
            <div
              v-if="$page && $page.core404.description"
              v-html="$page.core404.description"
              class="mb-space-lg"
            ></div>
          </template>
        </section-explore-atlas>
      </main>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { NuxtError } from "#app";
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { SectionExploreAtlas } from "@rds-vue-ui/section-explore-atlas";
import { ExploreItem } from "@rds-vue-ui/section-explore-atlas/SectionExploreAtlas.vue";
import { Core404 } from "~/server/resolvers/resolvers-types";
const UtilityService = useUtilityService();
const config = useRuntimeConfig();
const router = useRouter();
const { currentRoute } = router;

const props = defineProps({
  error: Object as () => NuxtError,
});

interface Core404PageData {
  core404: Core404;
}

const $page = await usePageQuery<Core404PageData>(
  `#graphql
      query {
        core404(id:"adad7655-140a-40e4-af0c-554df87661a2") {
          id
          detailPage
          metaTitle
          metaDescription
          title
          description
          exploreItems {
            exploreItems {
              image
              imageAlt
              title
              listItems {
                uri
                text
                target
              }
            }
          }
        }
      }`,
  {},
  {
    key: "404-page",
  }
);

definePageMeta({
  layout: false,
  __dangerouslyDisableSanitizersByTagID: {
    "ldjson-schema": ["innerHTML"],
  },
  route: "/404",
});

useHead({
  title: $page?.core404.metaTitle,
  meta: [
    { name: "Title", content: $page?.core404.metaTitle },
    {
      name: "Description",
      content: $page?.core404.metaDescription,
    },
  ],
  link: [
    {
      rel: "canonical",
      href: UtilityService.formatCanonicalUrl(config.public.siteUrl),
    },
  ],
});

const exploreItems = computed(() => {
  if ($page! && $page.core404.exploreItems!.exploreItems) {
    return $page?.core404.exploreItems?.exploreItems as ExploreItem[];
  }
});

const handleSelectItemClick = (event: Event, title: string) => {
  const selectTextTarget = event.target as HTMLElement;
  analyticsComposable.trackSelectEvent(
    "onclick",
    "open",
    "dropdown",
    "main content",
    title.toLowerCase(),
    `explore information about ${selectTextTarget.innerText.toLowerCase()}`
  );
};
const handleLinkClick = (event: Event, section: string) => {
  event.preventDefault();
  const eventTarget = event.target as HTMLLinkElement;

  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    section.toLowerCase(),
    eventTarget.innerText.toLowerCase()
  );
  const linkHref = eventTarget.href
    ? eventTarget.href
    : console.error("link missing href");
  const linkTarget = eventTarget.target ? eventTarget.target : "_blank";
  linkHref
    ? navigateTo(linkHref, {
        open: {
          target: linkTarget,
        },
      })
    : null;
};
const handleUpdateItemClick = (event: Event, section: string) => {
  if (event.target instanceof HTMLElement) {
    analyticsComposable.trackSelectEvent(
      "onclick",
      "click",
      "dropdown",
      "main content",
      section.toLowerCase(),
      event.target.innerText.toLowerCase()
    );
  } else {
    console.error("dropdown missing text value");
  }
};

onMounted(() => {
  console.error(props.error);
});
</script>

<style lang="scss" scoped>
.asuo-enter-active {
  transition: opacity 0.5s;
}
.asuo-enter {
  opacity: 0;
}
</style>
